import * as React from 'react'
import Layout from "../components/layout";
import {graphql, Link} from "gatsby";
import _ from "lodash";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import MetaData from "../components/metaData";

const BioPage = ({data}) => {
    const randomImage = _.sampleSize(data.all.nodes).map((node) => {
        const image = getImage(node);
        const gatsbyImage = <Link to={'/bio'} title={'Click to refresh image.'}><GatsbyImage alt={node.childImageSharp.fluid.originalName} image={image} /></Link>;
        const exifData = {
            // To calculate shutter speed: round(2 ** (exif shutter speed))
            // Source: https://photo.stackexchange.com/questions/108817/shutter-speed-from-the-exif-shutterspeedvalue
            'shutterSpeed': Math.round(2 ** node.childImageSharp.fields.exif.raw.exif.ShutterSpeedValue),
            'fNumber': node.childImageSharp.fields.exif.raw.exif.FNumber,
            'ISO': node.childImageSharp.fields.exif.raw.exif.ISO,
            'focalLength35': node.childImageSharp.fields.exif.raw.exif.FocalLengthIn35mmFormat,
            'camMake': node.childImageSharp.fields.exif.raw.image.Make,
            'camModel': node.childImageSharp.fields.exif.raw.image.Model,
            'lensMake': node.childImageSharp.fields.exif.raw.exif.LensMake,
            'lensModel': node.childImageSharp.fields.exif.raw.exif.LensModel,
        };
        if (node.childImageSharp.resize.aspectRatio > 1.0) {
            return (
                <div className="w-8/12">
                    {gatsbyImage}
                    <MetaData exifData={exifData} />
                </div>
            )
        } else {
            return (
                <div className="w-6/12">
                    {gatsbyImage}
                    <MetaData exifData={exifData} className={'flex-wrap'} />
                </div>
            )
        }
    });

    return (
        <Layout>
            <div className="flex justify-between gap-8">
                <article className={`w-4/12 flex flex-col justify-end`}>
                    <h1 className="text-4xl mb-6">Web Developer. <br/> <span className={'opacity-50'}>Amateur Photographer.</span></h1>
                    <p className={'opacity-50 text-sm leading-relaxed mb-6'}>Hi, welcome to my website! I'm currently working on mostly Wordpress websites and some custom web development projects like Laravel and GatsbyJS.</p>
                    <p className={'opacity-50 text-sm leading-relaxed mb-6'}>In my free time, I practice street photography and other forms like fashion and portrait photography for my beautiful wife's Instagram.</p>
                    <p className={'opacity-50 text-sm leading-relaxed mb-6'}>This personal website is dedicated for my interest in photography. Aside from my interest, it's an art form where I can express comfortably, and I just like sharing what I find in my everyday life.</p>
                    <p className={'opacity-50 text-sm leading-relaxed mb-6'}>If you need some professional help with web development, reach out to any of the channels below.</p>
                    <ul className={'flex flex-col mt-16'}>
                        <li key={1} className={'text-4xl'}><a href={'https://valiantweb.co/'} className={'opacity-25 hover:opacity-100 transition-all inline-block py-1'}>ValiantWeb</a></li>
                        <li key={2} className={'text-4xl'}><a href={'https://www.instagram.com/rol.jhn/'} className={'opacity-25 hover:opacity-100 transition-all inline-block py-1'}>Instagram</a></li>
                        <li key={3} className={'text-4xl'}><a href={'https://join.skype.com/invite/aSIcwx4TZxeS'} className={'opacity-25 hover:opacity-100 transition-all inline-block py-1'}>Skype</a></li>
                    </ul>
                </article>
                {randomImage}
            </div>
        </Layout>
    )
}

export default BioPage

export const query = graphql`
    query {
        all: allFile(
            filter: {
                extension: {regex: "/(jpg)|(jpeg)/"},
            }
            sort: {order: DESC, fields: modifiedTime}
        ) {
            nodes {
                childImageSharp {
                    gatsbyImageData(width: 1200, placeholder: BLURRED)
                    fluid {
                        originalName
                    }
                    resize {
                        aspectRatio
                    }
                    fields {
                        exif {
                            raw {
                                image {
                                    Make
                                    Model
                                }
                                exif {
                                    FNumber
                                    FocalLengthIn35mmFormat
                                    ISO
                                    LensMake
                                    LensModel
                                    ShutterSpeedValue
                                    FocalLength
                                }
                            }
                        }
                    }
                }
                relativeDirectory
            }
        }
    }
`

export const Head = () => <title>Bio - Rol John Torralba</title>
